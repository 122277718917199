<template>
  <div class="myPage">
    <div class="line"></div>
    <Form @submit="onSubmit" input-align="right" :show-error-message="false">
      <Field
        v-model.trim="form.mobile"
        name="手机号"
        label="手机号"
        placeholder="请输入手机号码"
        readonly
      />
      <Field
        v-model.trim="form.name"
        name="姓名"
        label="姓名"
        placeholder="请输入姓名"
        :maxlength="10"
      />
      <Field
        readonly
        clickable
        name="性别"
        :value="form.gender"
        label="性别"
        placeholder="请选择性别"
        right-icon="arrow"
        @click="handleShowPicker('gender')"
      />
      <Field
        v-model.trim="form.company"
        name="单位名称"
        label="单位名称"
        placeholder="请输入单位名称"
        :maxlength="50"
      />
      <Field
        v-model.trim="form.post"
        name="职务"
        label="职务"
        placeholder="请输入职务"
        :maxlength="10"
      />
      <Field
        v-model.trim="form.profession"
        name="职业"
        label="职业"
        placeholder="请输入职业"
        :maxlength="10"
      />
      <div class="line"></div>
      <Field
        readonly
        clickable
        name="picker"
        :value="form.brithday"
        label="生日"
        placeholder="请选择生日"
        right-icon="arrow"
        @click="handleShowPicker('born')"
      />
      <Field
        readonly
        clickable
        name="area"
        :value="form.area"
        label="城市"
        placeholder="点击选择地区"
        right-icon="arrow"
        @click="handleShowPicker('area')"
      />
      <Field
        readonly
        clickable
        name="学历"
        :value="form.education"
        label="学历"
        placeholder="请选择学历"
        right-icon="arrow"
        @click="handleShowPicker('education')"
      />

      <div style="margin: 16px">
        <Button class="btn" block color="#2b72ff" native-type="submit"
          >保存</Button
        >
      </div>
    </Form>
    <Popup v-model="showPicker" position="bottom">
      <datetime-picker
        v-if="pickerType == 'born'"
        type="date"
        v-model="currentDate"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirmPicker"
        @cancel="cancelPicker"
      />
      <Area
        v-else-if="pickerType == 'area'"
        :area-list="areaList"
        @confirm="onConfirmPicker"
        @cancel="cancelPicker"
      />
      <Picker
        v-else
        show-toolbar
        :columns="pickerList"
        @confirm="onConfirmPicker"
        @cancel="cancelPicker"
      />
    </Popup>
  </div>
</template>

<script>
import { Form, Field, Button, Popup, Picker, Area, DatetimePicker,Notify,Toast  } from "vant";
import { areaList } from "@vant/area-data";
import moment from "moment";
import {updateUserInfo} from '@/api/my.js'
export default {
  name: "myData",
  components: {
    Form,
    Field,
    Button,
    Popup,
    Picker,
    Area,
    DatetimePicker,
    Toast,
    [Notify.Component.name]: Notify.Component,
  },
  data() {
    return {
      showPicker: false,
      pickerList: [],
      pickerType: "",
      areaList,
      form: {
        id:'',
        mobile: "",
        name: "",
        gender: "男",
        company: "",
        post: "",
        profession: "",
        area: "",
        brithday: "",
        education: "",
      },
      currentDate: new Date(1990, 0, 1),
      minDate: new Date(1945, 0, 1),
      maxDate: new Date(),
      genderList: ["男", "女"],
      educationList: ["初中", "高中", "中专", "大专", "本科", "硕士", "博士"],
      myAreaList:[],
    };
  },
  created(){
    let info=this.$route.params
    if(!info.id){
      this.$router.go(-1)
    }
    let {id,
        mobile,
        name,
        gender,
        company,
        post,
        profession,
        area,
        brithday,
        education}=info;
    this.form={id,
        mobile,
        name,
        gender,
        company:company||'',
        post:post||'',
        profession:profession||'',
        area,
        brithday:brithday||'',
        education:education||''}
    this.form.gender=info.gender?(info.gender=='1'?"女":"男"):'';
    this.form.area=info.provinceName+info.cityName+info.countyName
    this.form.area=this.form.area==0?'':this.form.area
    this.myAreaList=[{code:info.province,name:info.provinceName},{code:info.city,name:info.cityName},{code:info.county,name:info.countyName}]

  },
  methods: {
    onSubmit() {
      let gender=this.form.gender?(this.form.gender=='男'?'0':'1'):'';
        let params=Object.assign({},this.form,{gender,province:this.myAreaList[0].code||'',provinceName:this.myAreaList[0].name||'',
        city:this.myAreaList[1].code||'',cityName:this.myAreaList[1].name||'',county:this.myAreaList[2].code||'',countyName:this.myAreaList[2].name||''})
        this.$delete(params,'area')
        updateUserInfo(params).then(res=>{
          if(res.status=="S"){
            Toast.success('提交成功');
            this.$router.replace("/my")
          }
        })
    },
    handleShowPicker(type) {
      if (type == "gender") {
        this.pickerList = [...this.genderList];
      }
      if (type == "education") {
        this.pickerList = [...this.educationList];
      }
      this.showPicker = true;
      this.pickerType = type;
    },
    onConfirmPicker(val) {
      switch (this.pickerType) {
        case "gender":
            this.form.gender=val;
          break;
        case "born":
            val = val.toLocaleDateString();
            this.form.brithday = moment(val).format("YYYY-MM-DD");
          break;
        case "area":
            var name = val[0].name + val[1].name + val[2].name;
            this.form.area=name;
            this.myAreaList=val;
          break;
        case "education":
            this.form.education=val;
          break;
        default:
          break;
      }
      this.showPicker = false;
      this.pickerType = "";
      this.pickerList = [];
    },
    cancelPicker() {
      this.showPicker = false;
      this.pickerType = "";
      this.pickerList = [];
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep.van-cell {
  padding: 0.15rem 0.16rem;
}
.myPage {
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  .line {
    height: 0.12rem;
    background: #f5f5f5;
  }
  .btn {
    border-radius: 5px;
    height: 0.42rem;
    margin-top: 0.3rem;
  }
}
</style>
